@media (max-width: 768px){
    .btn-grid{
        margin-top: 5px;
    }
}

.container-card {
    margin-top: 45px;
}

.card-detalhe {
    margin-top:  80px;
    border-radius: 8px;
}
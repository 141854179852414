@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap4.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap4.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap4.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap4.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap4.css";
@import "../node_modules/@syncfusion/ej2-react-dropdowns/styles/bootstrap4.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";


body {
    background: #f1f2fa;
}

.rodape {
    position: absolute;
    bottom: 0;
    color: red
}

.table-horiz-scroll {
    overflow-x: auto;
}

.sticky {
    position: sticky;
    top: 0;
    background: white;
}


.e-dlg-header, .e-dlg-header * {
    color: inherit;
}

.e-dlg-overlay {
    position: fixed !important;
}
